<template>
    <v-app>
      <v-container>
        <v-combobox
          v-model="selectedFruit"
          :items="fruits"
          label="Select or type a fruit"
          outlined
          dense
          clearable
          @input="updateEnteredText"
        ></v-combobox>
        <div>Selected Fruit: {{ selectedFruit }}</div>
        <v-btn @click="sendDataToBackend">Send to Backend</v-btn>
      </v-container>
    </v-app>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        selectedFruit: null,
        fruits: ['Apple', 'Banana', 'Orange', 'Grapes', 'Mango'],
        userEnteredText: '',
      };
    },
    methods: {
      updateEnteredText(value) {
        this.userEnteredText = value;
      },
      sendDataToBackend() {
        // Make an API call to send the selected fruit to the backend
        const dataToSend = {
          selectedFruit: this.selectedFruit,
          userEnteredText: this.userEnteredText,
        };
  
        // Replace 'your-backend-api-endpoint' with the actual endpoint
        axios.post('your-backend-api-endpoint', dataToSend)
          .then(response => {
            console.log('Data sent successfully:', response.data);
            // You can handle the response from the backend here
          })
          .catch(error => {
            console.error('Error sending data to backend:', error);
            // Handle errors from the backend API call
          });
      },
    },
    watch: {
      selectedFruit(value) {
        // Ensure that userEnteredText is updated even if @input doesn't trigger
        this.userEnteredText = value;
      },
    },
  };
  </script>
  